import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueSelect from "vue-select";
import store from "./store";
import { VueReCaptcha } from "vue-recaptcha-v3";

import "vue-select/dist/vue-select.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

// Main CSS Add
import "./assets/app.css";
import i18n from "@/translates/translationService";
// import axios from 'axios';

const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
// axios.defaults.headers['Accept-Language'] = localStorage.getItem("lang");

createApp(App)
	.use(store)
	.use(router)
	.component("v-select", VueSelect)
	.use(i18n)
	.use(VueReCaptcha, {
		siteKey: recaptchaSiteKey,
	  })
	.mount("#app");
