<template>
  <section id="go_beyond_area" class="section_padding_top">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="heading_left_area">
            <img src="../../assets/img/imagination/familia-travel.svg" />
            <h2 class="text-coral">{{ $t("special_offers") }}</h2>
            <h5>{{ $t("discover_an_ideal_experience_with_us") }}</h5>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="imagination_boxed">
            <img src="../../assets/img/imagination/imagination11.jpg" />
            <h3>
              <router-link to="/#!">{{
                $t("special_offers_always_available")
              }}</router-link>
            </h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="imagination_boxed" style="text-align: center">
            <img src="../../assets/img/imagination/imagination22.jpg" alt="img" />
            <h3>
              <router-link to="/#!">{{ $t("travel_discover_and_enjoy") }}</router-link>
            </h3>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-12">
          <div class="imagination_boxed">
            <img src="../../assets/img/imagination/imagination33.jpg" alt="img" />

            <h3>
              <router-link to="/#!">{{
                $t("luxury_resort_at_the_best_price")
              }}</router-link>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Imagination",
};
</script>
