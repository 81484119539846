<template>
  <section id="top_destinations" class="section_padding_top">
    <div class="container">
      <!-- Section Heading -->
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <h2 class="text-coral" style="text-align: center">{{ $t("destinations") }}</h2>
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
          <div
            class="destinations_content_box img_animation"
            @click="openInNewTab('Medellín')"
          >
            <img src="../../assets/img/destination/big-img.jpg" alt="img" />
            <div class="destinations_content_inner">
              <h2>Medellín</h2>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
              <div
                class="destinations_content_box img_animation"
                @click="openInNewTab('Guatapé')"
              >
                <img src="../../assets/img/destination/destination1.jpg" alt="img" />

                <div class="destinations_content_inner">
                  <h3>Guatapé</h3>
                </div>
              </div>
              <div
                class="destinations_content_box img_animation"
                @click="openInNewTab('Bogotá')"
              >
                <img src="../../assets/img/destination/destination2.jpg" alt="img" />

                <div class="destinations_content_inner">
                  <h3>Bogotá</h3>
                </div>
              </div>
              <div
                class="destinations_content_box img_animation"
                @click="openInNewTab('San Andrés')"
              >
                <img src="../../assets/img/destination/destination3.jpg" alt="img" />

                <div class="destinations_content_inner">
                  <h3>San Andrés</h3>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
              <div
                class="destinations_content_box img_animation"
                @click="openInNewTab('Manizales')"
              >
                <img src="../../assets/img/destination/destination4.jpg" alt="img" />

                <div class="destinations_content_inner">
                  <h3>Manizales</h3>
                </div>
              </div>
              <div
                class="destinations_content_box img_animation"
                @click="openInNewTab('Cali')"
              >
                <img src="../../assets/img/destination/destination5.jpg" alt="img" />

                <div class="destinations_content_inner">
                  <h3>Cali</h3>
                </div>
              </div>
              <div
                class="destinations_content_box img_animation"
                @click="openInNewTab('Cartagena')"
              >
                <img src="../../assets/img/destination/destination6.jpg" alt="img" />

                <div class="destinations_content_inner">
                  <h3>Cartagena</h3>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-4 col-sm-12 col-12"
              @click="openInNewTab('Santa Marta')"
            >
              <div class="destinations_content_box img_animation">
                <img src="../../assets/img/destination/destination7.jpg" alt="img" />

                <div class="destinations_content_inner">
                  <h3>Santa Marta</h3>
                </div>
              </div>
              <div
                class="destinations_content_box img_animation"
                @click="openInNewTab('Barranquilla')"
              >
                <img src="../../assets/img/destination/destination8.jpg" alt="img" />

                <div class="destinations_content_inner">
                  <h3>Barranquilla</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  name: "TopDestinations",
  data() {
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() + 1);
    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + 2);

    return {
      swiper: null,
      // city: "",
      items: [],
      defaultStartDate: startDate.toISOString().slice(0, 10),
      defaultEndDate: endDate.toISOString().slice(0, 10),
      customerNationality: "CO",
      rooms: 1,
      guests: [{ adults: 2, children: [] }],
    };
  },
  methods: {
    ...mapActions("form", ["fetchProperties"]),
    openInNewTab(city) {
      let queryParameters = {
        start_date: this.defaultStartDate,
        end_date: this.defaultEndDate,
        city: city,
        country: "CO",
        rooms: this.rooms,
        guests: this.guests,
        customerNationality: this.customerNationality,
        destiny_text: `${city} - Colombia`,
      };
      const serializeGuests = (guestsArray) => {
        return guestsArray
          .map(
            (guest) => `adults=[${guest.adults}],children=[${guest.children.join(",")}]`
          )
          .join("&");
      };

      const serializedGuests = serializeGuests(queryParameters.guests);

      queryParameters.guests = serializedGuests;

      const url = this.$router.resolve({
        name: "hotel-search",
        query: queryParameters,
      }).href;
      this.fetchProperties(queryParameters);
      window.open(url, "_blank", "noopener,noreferrer");
    },
  },
};
</script>
<style scoped>
.destinations_content_inner h3 {
  color: white;
}
</style>
