<template>
  <div
    class="promotional_tour_slider owl-theme owl-carousel dot_style"
    style="position: relative; z-index: 1"
  >
    <swiper
      :slides-per-view="1"
      :space-between="1"
      :pagination="{ clickable: true }"
      :autoplay="{ delay: 4000 }"
    >
      <swiper-slide v-for="banner in banners" :key="banner.id">
        <template v-if="isValidUrl(banner.url)">
          <a :href="banner.url" target="_blank" rel="noopener noreferrer">
            <section
              id="home_one_banner"
              :style="'background-image: url(' + banner.image + ')'"
            >
              <div class="overlay"></div>
              <div class="banner_one_text">
                {{ banner.title }}
              </div>
            </section>
          </a>
        </template>
        <template v-else>
          <section
            id="home_one_banner"
            :style="'background-image: url(' + banner.image + ')'"
          >
            <div class="overlay"></div>
            <div class="banner_one_text">
              {{ banner.title }}
            </div>
          </section>
        </template>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import axios from "axios";
import { API_BASE_URL, BANNERS_API } from "@/api-config";

export default {
  name: "Banner",

  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      swiper: null,
      banners: [],
      typeBanner: 2,
    };
  },

  mounted() {
    this.fetchBanners();
  },

  methods: {
    fetchBanners() {
      axios
        .get(`${API_BASE_URL}${BANNERS_API}${this.typeBanner}`)
        .then((response) => {
          this.banners = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching banners:", error);
        });
    },

    isValidUrl(url) {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    },
  },
};
</script>

<style scoped>
#home_one_banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.banner_one_text {
  position: relative;
  color: white;
  font-size: 65px;
  font-weight: bold;
  text-align: center;
  max-width: 55%;
  z-index: 2;
  line-height: 1.2em;
  font-weight: 300;
  font-family: Poppins, sans-serif;
}
</style>
