<template>
  <section id="tour_details_main" class="section_padding">
    <div class="container">
      <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="section_heading_center">
          <h2>{{ $t("property_details") }}</h2>
        </div>
      </div> -->
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="tour_details_leftside_wrapper">
            <div class="tour_details_heading_wrapper">
              <div class="tour_details_top_heading">
                <h2>{{ property.propertyInfo?.name }}</h2>
              </div>
            </div>
            <div class="tour_details_img_wrapper">
              <div class="image__gallery">
                <div class="gallery">
                  <div class="gallery__slideshow">
                    <div class="gallery__slides">
                      <div class="gallery__slide">
                        <img
                          class="gallery__img"
                          :src="slide.img.uri"
                          alt=""
                          v-for="(slide, index) in gallerySlides"
                          :key="index"
                          :style="
                            slideIndex === index ? 'display:block;' : 'display:none;'
                          "
                        />
                      </div>
                    </div>
                    <a @click.prevent="move(-1)" class="gallery__prev">&#10095;</a>
                    <a @click.prevent="move(1)" class="gallery__next">&#10094;</a>
                  </div>

                  <div class="gallery__content">
                    <div class="gallery__items">
                      <div
                        class="gallery__item"
                        :class="{
                          'gallery__item--is-acitve': slideIndex === index,
                        }"
                        v-for="(slide, index) in gallerySlides"
                        :key="`item-${index}`"
                      >
                        <img
                          :src="slide.img.uri"
                          @click="currentSlide(index)"
                          class="gallery__item-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tour_details_boxed">
              <h3 class="heading_theme">{{ $t("general_description") }}</h3>
              <div class="tour_details_boxed_inner">
                {{ property?.remarks }}
              </div>
              <div class="tour_package_details_bar_list">
                <h5>{{ $t("property_amenities") }}</h5>
                <ul>
                  <li v-for="amenity in amenities" :key="amenity.id">
                    <i class="fas fa-circle"></i>{{ amenity.name }}
                  </li>
                </ul>
              </div>
              <div class="tour_package_details_bar_list">
                <h5>{{ $t("policies") }}</h5>
                <div v-html="property.polices"></div>
              </div>
            </div>

            <div class="tour_details_boxed">
              <h3 class="heading_theme">
                {{ $t("availability") }}
              </h3>
              <div class="room_select_area">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <h4>{{ $t("book_with_us") }}</h4>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div class="room_booking_area">
                      <div class="tour_search_form">
                        <form @submit.prevent="fetchProperty()">
                          <div class="row">
                            <div class="col-lg-6 col-md-4 col-sm-12 col-12">
                              <div class="form_search_date">
                                <div class="flight_Search_boxed py-3 date_flex_area">
                                  <div class="Journey_date">
                                    <p>{{ $t("dates_of_stay") }}</p>
                                    <flat-pickr
                                      v-model="selectedDates"
                                      :config="dateConfig"
                                      :placeholder="
                                        $t('entry_date') + ' - ' + $t('departure_date')
                                      "
                                      class="w-100"
                                      @onChange="handleDateChange"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <CountDropdownHotel
                              :props_nationality="props_nationality"
                              :props_guests="props_guests"
                              @updateGuests="handleUpdateGuests"
                              @updatecustomerNationality="setcustomerNationality"
                            />
                            <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                              <div
                                @click="refetchProperty()"
                                :class="[
                                  'btn',
                                  'search',
                                  'btn_theme',
                                  'd-flex',
                                  'align-items-center',
                                  'justify-content-center',
                                  'flight_Search_boxed',
                                  { disabled: isLoading },
                                ]"
                                :disabled="isLoading"
                              >
                                {{ $t("search") }}
                                <i
                                  v-if="!isLoading"
                                  class="px-2 fas fa-angle-right text-coral"
                                ></i>
                                <div v-else class="loader"></div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div>
                        <br />
                        <table>
                          <thead>
                            <tr>
                              <th>{{ $t("room_type") }}</th>
                              <th>{{ $t("number_of_people") }}</th>
                              <th>{{ $t("price") }}</th>
                              <th>{{ $t("your_options") }}</th>
                              <th>{{ $t("select_rooms") }}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="!property.rooms || property.rooms.length === 0">
                              <td
                                colspan="6"
                                style="
                                  text-align: center;
                                  font-style: normal;
                                  font-size: 1.2em;
                                "
                              >
                                {{ $t("no_accommodation_available") }}
                              </td>
                            </tr>
                            <tr
                              v-for="(room, index) in property.rooms"
                              :key="room.roomId"
                            >
                              <td @click="modalToggle(room)">
                                {{ room.roomName }}
                              </td>
                              <td>
                                <i class="fa fa-users"></i>
                                {{ room.searchedPax.adults }}
                              </td>
                              <td>
                                <ul class="list-prices center-data">
                                  <li
                                    v-for="ratePlan in room.ratePlans"
                                    :key="ratePlan.id"
                                  >
                                    {{
                                      formatNumber(ratePlan.payment.chargeAmount.amount) +
                                      " " +
                                      ratePlan.payment.chargeAmount.currency
                                    }}
                                    <br />
                                    <div v-if="ratePlan.payment.taxesFeesAdd.length > 0">
                                      <div
                                        v-for="taxFee in ratePlan.payment.taxesFeesAdd"
                                        :key="taxFee.Total"
                                      >
                                        + {{ taxFee.Total }} {{ $t("additional_taxes") }}
                                      </div>
                                    </div>
                                    <div
                                      v-if="ratePlan.payment.taxesFeesDisplay.length > 0"
                                    >
                                      <div
                                        v-for="taxFee in ratePlan.payment
                                          .taxesFeesDisplay"
                                        :key="taxFee.Total"
                                      >
                                        +
                                        {{
                                          taxFee.Total.toLocaleString("es-ES", {
                                            minimumFractionDigits: 2,
                                          }) +
                                          " " +
                                          ratePlan.payment.chargeAmount.currency
                                        }}
                                        {{ $t("local_taxes_and_charges") }}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <ul class="list-prices center-data">
                                  <li
                                    v-for="ratePlan in room.ratePlans"
                                    :key="ratePlan.id"
                                  >
                                    <div v-for="option in ratePlan.options" :key="option">
                                      {{ option.title }}
                                      <br />
                                      {{ option.text }}
                                    </div>
                                  </li>
                                </ul>
                              </td>
                              <td>
                                <ul class="list-prices center-data">
                                  <li
                                    v-for="(ratePlan, ratePlanIndex) in room.ratePlans"
                                    :key="ratePlan.id"
                                  >
                                    <select
                                      v-model="selectedOptions[index][ratePlanIndex]"
                                      @change="
                                        updateDisabledOptions(index, ratePlanIndex)
                                      "
                                    >
                                      <option value="0" selected>0</option>
                                      <option
                                        v-for="n in room.numberOfAvailableRooms"
                                        :key="n"
                                        :value="n"
                                        :disabled="validateDisabledOptions(index, n)"
                                      >
                                        {{ n }}
                                        {{ ratePlan.payment.chargeAmount.currency }}
                                        {{
                                          (
                                            n * ratePlan.payment.chargeAmount.amount
                                          ).toLocaleString("es-ES", {
                                            minimumFractionDigits: 2,
                                          })
                                        }}
                                      </option>
                                    </select>
                                  </li>
                                </ul>
                              </td>
                              <td v-if="index === 0">
                                <button @click="handleReservationClick">
                                  {{ $t("i_will_reserve") }}
                                </button>
                              </td>
                              <td v-else></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="container">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="write_your_review_wrapper">
            <h3 class="heading_theme">{{ $t("write_your_review") }}</h3>
            <div class="write_review_inner_boxed">
              <form action="!#" id="news_comment_form">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-froup">
                      <input
                        type="text"
                        class="form-control bg_input"
                        :placeholder="$t('full_name')"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-froup">
                      <input
                        type="text"
                        class="form-control bg_input"
                        :placeholder="$t('email')"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-froup">
                      <textarea
                        rows="6"
                        :placeholder="$t('write_your_comments')"
                        class="form-control bg_input"
                      ></textarea>
                    </div>
                    <div class="comment_form_submit">
                      <button class="btn btn_theme btn_md">
                        {{ $t("post_comment") }}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import CountDropdownHotel from "@/components/hotel/CountDropdownHotel.vue";
import axios from "axios";
import { mapState, mapActions } from "vuex";
import { API_BASE_URL, PROPERTY_API } from "@/api-config";
import FlatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import "swiper/swiper-bundle.css";
import Swal from "sweetalert2";

export default {
  name: "HotelSearch",
  components: {
    CountDropdownHotel,
    Swiper,
    SwiperSlide,
    FlatPickr,
  },
  props: {
    props_guests: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      national: {},
      guests: [],
      active: false,
      start_date: "",
      end_date: "",
      key: "",
      selectedRoomType: { roomName: String, images: [] },
      roomTypes: [],
      gallerySlides: [],
      slideIndex: 0,
      slides: [],
      property: {},
      amenities: [],
      customWidth: 480,
      customHeight: 240,
      selectedRooms: [],
      selectedOptions: [],
      disabledOptions: [],
      customerNationality: "",
      isLoading: false,
      selectedDates: [],
      dateConfig: {
        locale: Spanish,
        mode: "range",
        minDate: "today",
        showMonths: 2,
        formatDate: (date) => {
          return date.toISOString().slice(0, 10);
        },
        defaultDate: this.selectedDates,
      },
    };
  },

  mounted() {
    this.start_date = this.$route.query.start_date;
    this.end_date = this.$route.query.end_date;
    this.guests = this.$route.query.guests;
    this.fetchProperty();
    if (this.property && this.property.rooms) {
      this.initializeSelectedOptions();
    }
    this.customerNationality = this.$route.query.customerNationality;

    if (this.$route.query.start_date && this.$route.query.end_date) {
      this.selectedDates = [this.$route.query.start_date, this.$route.query.end_date];
    }
  },

  computed: {
    ...mapState({
      adults: (state) => state.form.adults,
      children: (state) => state.form.children,
    }),
    hasSelectedRooms() {
      return this.selectedOptions.some((roomOptions) =>
        roomOptions.some((quantity) => quantity > 0)
      );
    },
  },

  methods: {
    ...mapActions("form", ["updateCapacity", "updateGuests", "updateError"]),

    handleUpdateGuests(guestData) {
      this.guests = guestData;
    },

    setcustomerNationality(customerNationality) {
      this.national = customerNationality;
    },

    handleDateChange(selectedDates) {
      if (selectedDates.length === 2) {
        const formatDate = (date) => {
          const d = new Date(date);
          const year = d.getFullYear();
          const month = String(d.getMonth() + 1).padStart(2, "0");
          const day = String(d.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        };

        this.start_date = formatDate(selectedDates[0]);
        this.end_date = formatDate(selectedDates[1]);
      }
    },

    handleReservationClick() {
      if (!this.hasSelectedRooms) {
        this.showAlert(this.$t("please_select_at_least_one_room"));
        return;
      }

      this.$router.push({
        name: "room-booking",
        params: {
          id: this.property.propertyId,
        },
        query: {
          key: this.$route.params.key,
          propertyName: this.property.propertyInfo.name,
          propertyContact: JSON.stringify(this.property.contact),
          start_date: this.start_date,
          end_date: this.end_date,
          rooms: JSON.stringify(this.getSelectedRooms()),
          customerNationality: this.$route.query.customerNationality,
        },
      });
    },
    showAlert(errorMessage) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMessage,
      }).then((confirm) => {
        this.updateError({ status: false, message: "" });
      });
    },

    modalToggle(roomType) {
      const body = document.querySelector("body");
      this.active = !this.active;
      this.active
        ? body.classList.add("modal-open")
        : body.classList.remove("modal-open");
      this.selectedRoomType = roomType;
    },

    formatNumber(number) {
      return new Intl.NumberFormat("es-ES", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        useGrouping: true,
      }).format(number);
    },

    move(n) {
      if (this.gallerySlides.length <= this.slideIndex + n) {
        this.slideIndex = 0;
      } else if (this.slideIndex + n < 0) {
        this.slideIndex = this.gallerySlides.length - 1;
      } else {
        this.slideIndex += n;
      }
    },
    currentSlide(index) {
      this.slideIndex = index;
    },

    initializeSelectedOptions() {
      this.selectedOptions = this.property.rooms.map((room) =>
        room.ratePlans.map(() => 0)
      );
    },

    isOptionDisabled(roomIndex, ratePlanIndex, quantity) {
      const room = this.property.rooms[roomIndex];
      const rpIndex = room.ratePlans.findIndex((rp, index) => index === ratePlanIndex);

      if (room.disabledOptions && room.disabledOptions[rpIndex]) {
        return room.disabledOptions[rpIndex][quantity - 1];
      }
      return false;
    },

    updateDisabledOptions(roomIndex, ratePlanIndex) {
      const selectedQuantity = this.selectedOptions[roomIndex][ratePlanIndex];
      const room = this.property.rooms[roomIndex];

      room.disabledOptions = [];

      if (!room.disabledOptions) {
        room.disabledOptions = room.ratePlans.map(() =>
          Array(room.numberOfAvailableRooms).fill(false)
        );
      }

      room.ratePlans.forEach((_, rpIndex) => {
        if (!room.disabledOptions[rpIndex]) {
          room.disabledOptions[rpIndex] = Array(room.numberOfAvailableRooms).fill(false);
        }

        if (rpIndex === ratePlanIndex) {
          room.disabledOptions[rpIndex] = room.disabledOptions[rpIndex].map((_, n) => {
            return n < selectedQuantity;
          });
        }
      });
    },

    async fetchPropertyData(key, id, params) {
      try {
        const response = await axios.get(`${API_BASE_URL}${PROPERTY_API}${key}/${id}`, {
          params,
        });

        return response.data[0];
      } catch (error) {
        throw error;
      }
    },

    async fetchProperty() {
      try {
        const params = this.prepareParams();
        const data = await this.fetchPropertyData(
          this.$route.params.key,
          this.$route.params.id,
          params
        );
        this.processPropertyData(data);
      } catch (error) {
        this.handleError(error);
      }
    },

    async refetchProperty(newGuests) {
      this.isLoading = true;
      try {
        if (newGuests) {
          this.guests = newGuests;
        }
        const params = this.prepareParams();
        const data = await this.fetchPropertyData(
          this.$route.params.key,
          this.$route.params.id,
          params
        );
        this.processPropertyData(data);

        const serializeGuests = (guestsArray) => {
          if (!Array.isArray(guestsArray)) {
            console.error("serializeGuests espera un arreglo:", guestsArray);
            return "";
          }
          return guestsArray
            .map(
              (guest) => `adults=[${guest.adults}],children=[${guest.children.join(",")}]`
            )
            .join("&");
        };

        const serializedGuests = Array.isArray(this.guests)
          ? serializeGuests(this.guests)
          : this.guests;

        let queryParams = {
          start_date: this.start_date,
          end_date: this.end_date,
          guests: serializedGuests,
          rooms: this.guests.length,
          customerNationality: this.$route.query.customerNationality,
        };
        this.$router.push({
          name: "hotel-details",
          params: { key: this.$route.params.key, id: this.$route.params.id },
          query: queryParams,
        });
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },

    prepareParams() {
      const deserializeGuests = (guestsInput) => {
        if (Array.isArray(guestsInput)) {
          return guestsInput;
        }

        if (typeof guestsInput === "string") {
          return guestsInput.split("&").map((guest) => {
            const adultsMatch = guest.match(/adults=\[(\d+)\]/);
            const childrenMatch = guest.match(/children=\[([\d,]*)\]/);

            return {
              adults: adultsMatch ? parseInt(adultsMatch[1], 10) : 0,
              children:
                childrenMatch && childrenMatch[1]
                  ? childrenMatch[1].split(",").map(Number)
                  : [],
            };
          });
        }
        throw new TypeError("El parámetro guestsInput debe ser una cadena o un array.");
      };

      const guestsArray = deserializeGuests(this.guests);

      return {
        start_date: this.start_date,
        end_date: this.end_date,
        guests: guestsArray,
        rooms: guestsArray.length,
        customerNationality: this.$route.query.customerNationality,
      };
    },

    processPropertyData(data) {
      this.property = data;
      this.gallerySlides = data.images.map((image) => ({ img: image }));
      this.roomTypes = data.rooms;
      this.amenities = data.facilities;
      this.initializeSelectedOptions();
    },

    handleError(error) {
      if (error.response && error.response.data && error.response.data.message) {
        this.showAlert(error.response.data.message);
      } else {
        this.showAlert("Error desconocido");
      }
    },

    validateDisabledOptions(rIndex, value) {
      const room = this.property.rooms[rIndex];
      const selectedQuantityInCurrentRoom = this.selectedOptions[rIndex];

      const totalSelected = selectedQuantityInCurrentRoom.reduce(
        (total, qty) => total + qty,
        0
      );

      const remainingRooms = room.numberOfAvailableRooms - totalSelected;

      if (value > remainingRooms) {
        return true;
      }

      return false;
    },

    formatNumber(number) {
      return new Intl.NumberFormat("es-ES", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        useGrouping: true,
      }).format(number);
    },

    getSelectedRooms() {
      const selectedRooms = [];
      this.property.rooms.forEach((room, roomIndex) => {
        room.ratePlans.forEach((ratePlan, ratePlanIndex) => {
          const selectedQuantity = this.selectedOptions[roomIndex][ratePlanIndex];
          if (selectedQuantity > 0) {
            const taxesFeesAddTotal = ratePlan.payment.taxesFeesAdd.length
              ? parseFloat(ratePlan.payment.taxesFeesAdd[0].Total)
              : 0;
            const taxesFeesDisplayTotal = ratePlan.payment.taxesFeesDisplay.length
              ? parseFloat(ratePlan.payment.taxesFeesDisplay[0].Total)
              : 0;
            selectedRooms.push({
              roomId: room.roomId,
              guestAdults: room.searchedPax.adults,
              guestChildren: room.searchedPax.children,
              roomName: room.roomName,
              selectedQuantity: selectedQuantity,
              searchCurrency: ratePlan.payment.chargeAmount.searchCurrency,
              currency: ratePlan.payment.chargeAmount.currency,
              commission: ratePlan.payment.chargeAmount.commission,
              totalPrice: ratePlan.payment.chargeAmount.total,
              pricePerRoom: ratePlan.payment.chargeAmount.amount,
              discount: ratePlan.payment.chargeAmount.discount,
              promotion: ratePlan.payment.chargeAmount.promotion,
              data: ratePlan.payment.chargeAmount.data,
              totalTaxesDisplay: taxesFeesDisplayTotal,
              ratePlanCode: ratePlan.code,
              ratePlanId: ratePlan.id,
            });
          }
        });
      });

      return selectedRooms;
    },
  },
};
</script>
<style scoped>
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

table thead th {
  background-color: #0071c2;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 3px solid #004080;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 4px;
  border: 1px solid #ccc;
  text-align: left;
  vertical-align: middle;
}

th:first-child,
td:first-child {
  width: 25%;
}

th:nth-child(2),
td:nth-child(2) {
  width: 10%;
  vertical-align: middle;
  text-align: center;
}

th:nth-child(3),
td:nth-child(3) {
  width: 15%;
  vertical-align: middle;
  text-align: center;
}

td:nth-child(3) ul li {
  text-align: left;
}

th:nth-child(4),
td:nth-child(4) {
  width: 25%;
  vertical-align: middle;
  text-align: left;
}

th:nth-child(5),
td:nth-child(5) {
  width: 10%;
  text-align: center;
  vertical-align: middle;
}

th:last-child,
td:last-child {
  width: 15%;
  border: none;
  vertical-align: top;
}

.center-data {
  justify-content: space-between;
  text-align: justify;
  flex-direction: column;
  display: flex;
  height: fit-content;
}

.list-prices {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-prices li {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  height: 200px;
  max-height: max-content;
}

.list-prices li:last-child {
  border: none;
}
.prices-column {
  display: flex;
  flex-direction: column; /* Alinea el contenido como columna */
  justify-content: center; /* Centrado vertical */
  align-items: center; /* Centrado horizontal */
  text-align: center; /* Asegura alineación del texto */
  height: 100%; /* Asegura que ocupa toda la altura de la celda */
}

button {
  background-color: #0071c2;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 30px;
  margin-left: 10px;
}

button:hover {
  background-color: #005b9e;
}

tr {
  min-height: 50px;
}

option:disabled {
  color: gray;
  background-color: #f0f0f0;
}

.gallery__img {
  width: 100%;
  max-width: 800px;
  height: 450px;
  object-fit: cover;
  margin: 0 auto;
  margin-bottom: 20px;
}

.gallery__item-img {
  width: 90%;
  height: 120px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease-in-out;
  margin-bottom: 20px;
}

.gallery__item-img:hover,
.gallery__item--is-acitve .gallery__item-img {
  border-color: #007bff;
}

.section_padding {
  padding: 40px 0;
}
</style>
