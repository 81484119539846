<template>
  <!-- Common Banner Area -->
  <!-- <PrivacyPolicyBanner /> -->

  <!-- Privacy Policy Areas -->
  <PrivacyPolicyContent />

  <!-- Cta Area 
	<Cta />-->
</template>
<script>
import PrivacyPolicyBanner from "@/components/about/AboutUsBanner.vue";
import PrivacyPolicyContent from "@/components/about/AboutUsContent.vue";
import Cta from "@/components/home/Cta.vue";

export default {
  name: "AboutUsView",
  components: {
    PrivacyPolicyBanner,
    PrivacyPolicyContent,
    Cta,
  },
};
</script>
