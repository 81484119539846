<template>
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="tour_details_right_sidebar_wrapper">
            <div class="tour_detail_right_sidebar">
              <div class="tour_details_right_boxed">
                <div class="tour_details_right_box_heading">
                  <h3>{{ propertyName }}</h3>
                </div>
              </div>
              <div class="tour_details_right_boxed">
                <div class="tour_details_right_box_heading">
                  <h3>Datos de la reserva</h3>
                </div>
                <div class="valid_date_area">
                  <div class="valid_date_area_one">
                    <h5>Entrada</h5>
                    <p>{{ start_date }}</p>
                  </div>
                  <div class="valid_date_area_one">
                    <h5>Salida</h5>
                    <p>{{ end_date }}</p>
                  </div>
                </div>
                <hr />
                <div class="tour_details_right_box_heading">
                  <h4>Duración total de la estancia</h4>
                  <h5>{{ diffInDays }} {{ diffInDays > 1 ? "noches" : "noche" }}</h5>
                </div>
                <hr />
                <div class="tour_package_details_bar_list">
                  <h5>Habitaciones seleccionadas</h5>
                  <ul>
                    <li v-for="room in selectedRooms">
                      <i class="fas fa-circle"></i>
                      {{ room.selectedQuantity }} x
                      {{ room.roomName }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="tour_detail_right_sidebar">
              <div class="tour_details_right_boxed">
                <div class="tour_details_right_box_heading">
                  <h3>Desglose del precio</h3>
                </div>

                <div class="tour_booking_amount_area">
                  <ul>
                    <li>
                      Estadia por {{ diffInDays }} {{ diffInDays > 1 ? "noches" : "noche"
                      }}<span>
                        {{ selectedRooms.length > 0 ? selectedRooms[0].currency : "" }}
                        {{
                          totalAmount.toLocaleString("es-ES", {
                            minimumFractionDigits: 2,
                          })
                        }}</span
                      >
                    </li>
                  </ul>
                  <div class="tour_bokking_subtotal_area">
                    <h6>
                      Subtotal
                      <span>
                        {{ selectedRooms.length > 0 ? selectedRooms[0].currency : "" }}
                        {{
                          totalAmount.toLocaleString("es-ES", {
                            minimumFractionDigits: 2,
                          })
                        }}</span
                      >
                    </h6>
                  </div>
                  <div class="total_subtotal_booking">
                    <h6>
                      Monto total
                      <span>
                        {{ selectedRooms.length > 0 ? selectedRooms[0].currency : "" }}
                        {{
                          totalAmount.toLocaleString("es-ES", {
                            minimumFractionDigits: 2,
                          })
                        }}</span
                      >
                    </h6>
                    <sub>*Impuestos incluídos</sub>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="totalTaxesDisplayAmount > 0" class="tour_detail_right_sidebar">
              <div class="tour_details_right_boxed">
                <div class="tour_details_right_box_heading">
                  <h3>Información sobre el Precio</h3>
                </div>

                <div class="tour_booking_amount_area">
                  <ul>
                    <li>
                      No incluye
                      {{ selectedRooms.length > 0 ? selectedRooms[0].currency : "" }}
                      {{
                        totalTaxesDisplayAmount.toLocaleString("es-ES", {
                          minimumFractionDigits: 2,
                        })
                      }}
                      de impuestos y cargos locales
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="tou_booking_form_Wrapper">
            <div class="booking_tour_form">
              <h3 class="heading_theme">Envío de reserva</h3>
              <div class="tour_booking_form_box">
                <form action="!#" id="tour_bookking_form_item">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control bg_input"
                          placeholder="Nombres*"
                          v-model="form.first_name"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control bg_input"
                          placeholder="Apellidos*"
                          v-model="form.last_name"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control bg_input"
                          placeholder="Dirección*"
                          v-model="form.address"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <v-select
                          class="form-control bg_input"
                          v-model="countrySelected"
                          label="text"
                          :placeholder="$t('country')"
                          :disabled="true"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control bg_input"
                          placeholder="Correo electrónico*"
                          v-model="form.email"
                        />
                        <sub> El e-mail de confirmación se enviará a esta dirección </sub>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control bg_input"
                          placeholder="Numero de teléfono móvil*"
                          v-model="form.phone"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          type="date"
                          value=""
                          class="form-control bg_input"
                          v-model="form.birthdate"
                        />
                        <sub for="dates">{{ $t("birthdate") }}*</sub>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <sub> Peticiones especiales </sub>
                    <textarea
                      rows="5"
                      class="form-control bg_input"
                      placeholder="Si tienes alguna peticion especial escribela en inglés o en español"
                      v-model="form.specialRequests"
                    ></textarea>
                  </div>
                </form>
              </div>
            </div>
            <div class="booking_tour_form_submit">
              <div class="form-check write_spical_check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="form.acceptTerms"
                  id="flexCheckDefaultf1"
                />
                <label
                  class="form-check-label"
                  for="flexCheckDefaultf1"
                  @click="openInNewTab()"
                >
                  <span class="main_spical_check">
                    <span />
                    Acepto todos los términos y condiciones
                  </span>
                </label>
              </div>
              <br />
              <button class="btn btn_theme btn_md" @click="saveBooking">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import { mapState, mapActions } from "vuex";
import {
  API_BASE_URL,
  COUNTRY_API,
  SAVE_HGS_BOOKING_API,
  SAVE_CHN_BOOKING_API,
} from "@/api-config";
export default {
  name: "TourBookingSubmission",
  data() {
    return {
      countries: [],
      countrySelected: null,
      propertyName: "",
      propertyId: "",
      start_date: "",
      end_date: "",
      selectedRooms: [],
      diffInDays: 0,
      form: {
        first_name: "",
        last_name: "",
        address: "",
        country: "",
        email: "",
        phone: "",
        birthdate: "",
        specialRequests: [],
        acceptTerms: false,
      },
    };
  },
  computed: {
    ...mapState({
      error: (state) => state.form.error,
    }),

    formatDate() {
      if (!this.form.birthdate) return "";
      const [year, month, day] = this.form.birthdate.split("-");
      return `${month}/${day}/${year}`;
    },

    filteredCountries() {
      if (this.searchCountry) {
        return this.countries.filter((country) =>
          country.text.toLowerCase().includes(this.searchCountry.toLowerCase())
        );
      }
      return this.countries;
    },

    totalAmount() {
      if (Array.isArray(this.selectedRooms)) {
        return this.selectedRooms.reduce((total, room) => {
          return total + room.selectedQuantity * room.totalPrice;
        }, 0);
      }
      return 0;
    },

    totalTaxesDisplayAmount() {
      if (Array.isArray(this.selectedRooms)) {
        return this.selectedRooms.reduce((total, room) => {
          return total + room.totalTaxesDisplay;
        }, 0);
      }
      return 0;
    },
  },
  mounted() {
    this.getCountries();
    this.selectedRooms = Array.isArray(JSON.parse(this.$route.query.rooms))
      ? JSON.parse(this.$route.query.rooms)
      : [];
    this.propertyId = this.$route.params.id;
    this.propertyName = this.$route.query.propertyName;
    this.propertyContact = this.$route.query.propertyContact;
    this.start_date = this.$route.query.start_date;
    this.end_date = this.$route.query.end_date;
    this.customerNationality = this.$route.query.customerNationality;
    this.key = this.$route.query.key;
    this.calculateDiffInDays();
    console.log(this.customerNationality);
  },
  methods: {
    ...mapActions("form", ["updateError"]),

    updateDate(date) {
      if (date) {
        const [year, month, day] = date.split("-");
        this.form.birthdate = `${year}-${month}-${day}`;
      }
    },

    showAlert(message, type) {
      Swal.fire({
        icon: type,
        title: type === "success" ? "Éxito" : "Error",
        text: message,
      }).then((confirm) => {
        this.updateError({ status: false, message: "" });
      });
    },

    getCountries() {
      axios
        .get(`${API_BASE_URL}${COUNTRY_API}`)
        .then((response) => {
          this.countries = response.data.results;
          let selectedcustomerNationality = this.countries.find(
            (nat) => nat.slug === this.customerNationality
          );
          if (selectedcustomerNationality) {
            this.countrySelected = selectedcustomerNationality.text;
          } else {
            console.log("No se encontró el país con ese slug.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    calculateDiffInDays() {
      if (this.start_date && this.end_date) {
        this.diffInDays =
          (new Date(this.end_date) - new Date(this.start_date)) / (1000 * 3600 * 24);
      }
    },

    openInNewTab() {
      const url = this.$router.resolve({
        name: "terms-service",
      }).href;

      window.open(url, "_blank", "noopener,noreferrer");
    },

    validateForm() {
      const messages = {
        first_name: this.$t("please_enter_your_first_name"),
        last_name: this.$t("please_enter_your_last_name"),
        address: this.$t("please_enter_your_address"),
        email: this.$t("please_enter_your_email"),
        phone: this.$t("please_enter_your_phone_number"),
        birthdate: this.$t("please_select_your_date_of_birth"),
        acceptTerms: this.$t("please_accept_terms_and_conditions"),
      };

      for (const [key, message] of Object.entries(messages)) {
        if (!this.form[key] || (key === "countrySelected" && !this.countrySelected)) {
          return message;
        }
      }

      return null;
    },

    saveBooking() {
      const validationError = this.validateForm();
      if (validationError) {
        this.showAlert(validationError, "error");
        return;
      }

      const _first = this.form.first_name;
      const _last = this.form.last_name;
      const guestsPerRoom = (room) => {
        const guests = [];
        for (let i = 0; i < room.guestAdults; i++) {
          guests.push({
            birthDate: this.form.birthdate,
            name: {
              first: _first,
              last: _last,
            },
          });
        }

        Array.from(room.guestChildren).forEach(function (element) {
          const today = new Date();

          today.setFullYear(today.getFullYear() - element);

          const day = String(today.getDate()).padStart(2, "0");
          const month = String(today.getMonth() + 1).padStart(2, "0");
          const year = today.getFullYear();

          const formattedDate = `${year}/${month}/${day}`;

          guests.push({
            birthDate: formattedDate,
            name: {
              first: _first,
              last: _last,
            },
          });
        });
        return guests;
      };

      const specialRequestsArray = Array.isArray(this.form.specialRequests)
        ? this.form.specialRequests
        : this.form.specialRequests
        ? this.form.specialRequests
            .split("\n")
            .map((line) => line.trim())
            .filter((line) => line.length > 0)
        : [];

      const bookingData = {
        dates: {
          from: this.start_date,
          to: this.end_date,
        },
        propertyId: this.propertyId,
        propertyName: this.propertyName,
        propertyContact: JSON.parse(this.propertyContact),
        leadGuest: {
          birthDate: this.formatDate,
          contact: {
            address: this.form.address,
            city: "N/A",
            country: this.customerNationality,
            email: this.form.email,
            phone: this.form.phone,
            state: "N/A",
            zip: "N/A",
          },
          name: {
            first: this.form.first_name,
            last: this.form.last_name,
          },
          title: "MR",
        },
        rooms: [],
      };

      this.selectedRooms.forEach((room) => {
        for (let i = 0; i < room.selectedQuantity; i++) {
          bookingData.rooms.push({
            roomId: room.roomId,
            roomName: room.roomName,
            rateCode: room.ratePlanCode,
            expectedPrice: {
              searchCurrency: room.searchCurrency,
              currency: room.currency,
              commission: room.commission,
              total: room.totalPrice,
              amount: room.pricePerRoom,
              discount: room.discount,
              promotion: room.promotion,
              data: room.data,
            },
            guests: guestsPerRoom(room),
            specialRequests: specialRequestsArray,
          });
        }
      });

      const token = localStorage.getItem("authToken");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      const apiUrl =
        this.key === "chn"
          ? `${API_BASE_URL}${SAVE_CHN_BOOKING_API}`
          : `${API_BASE_URL}${SAVE_HGS_BOOKING_API}`;

      console.log(bookingData);

      axios
        .post(apiUrl, bookingData, { headers })
        .then((response) => {
          if (response.data && response.data.message) {
            const successMessage = response.data.message;
            this.showAlert(successMessage, "success");
          }

          const bookingDataString = JSON.stringify(bookingData);

          this.$router.push({
            name: "booking-confirmation",
            query: {
              pay_link: response.data.data.pay_link,
              booking_data: bookingDataString,
            },
          });

          this.form = {
            first_name: "",
            last_name: "",
            email: "",
            address: "",
            city: "",
            country: "",
            phone: "",
            birthDate: "",
            state: "",
            zip: "",
            specialRequests: [],
          };
          this.countrySelected = null;
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.message) {
            const errorMessage = error.response.data.message;
            this.showAlert(errorMessage, "error");
          } else {
            this.showAlert("Error desconocido");
          }
        });
    },
  },
};
</script>
