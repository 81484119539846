import axios from "axios";
import { API_BASE_URL, PROPERTIES_API } from "@/api-config";

const state = {
	items: [],
	adults: 0,
	children: [],
	results: {
		cities: [],
		states: [],
		countries: [],
		allOptions: [],
	},
	currentPage: 1,
	totalPages: 1,
	paginationLinks: {
		first: null,
		last: null,
		prev: null,
		next: null,
		pages: [],
	},
	number: 0,
	error: {
		status: false,
		message: "",
	},
	nationality: "",
	guests: [],
	start_date: "",
	end_date: "",
};

const mutations = {

	SET_GUESTS(state, guests) {
		state.guests = guests;
	},

	SET_NATIONALITY(state, nationality) {
		state.nationality = nationality;
	  },
	
	SET_CAPACITY(state, { adults, children }) {
		state.adults = adults;
		state.children = children;
	},
	SET_ERROR(state, error) {
		state.error = error;
	},
	SET_ITEMS(state, items) {
		state.items = items.items;
		state.paginationLinks = items.paginationLinks;
		state.totalPages = items.totalPages;
		state.currentPage = items.currentPage;
	},
};

const actions = {

	updateGuests({ commit }, guests) {
		commit("SET_GUESTS", guests);
	},

	updateNationality({ commit }, { nationality }) {
		commit('SET_NATIONALITY', nationality);
	},

	updateCapacity({ commit }, { adults, children }) {
		commit("SET_CAPACITY", { adults, children });
	},

	updateError({ commit }, error) {
		commit("SET_ERROR", error);
	},

	async fetchProperties({ commit, state }, queryParams) {
		let params = {
			start_date: queryParams.start_date,
			end_date: queryParams.end_date,
			city: queryParams.city,
			country: queryParams.country,
			rooms: queryParams.rooms,
			guests: queryParams.guests,
			language: "ES",
			page: queryParams.page == "undefined" ? 1 : queryParams.page,

		};

		if (queryParams.min_price !== "undefined") {
			params.min_price = queryParams.min_price;
		}

		if (queryParams.max_price !== "undefined") {
			params.max_price = queryParams.max_price;
		}

		try {
			const URL = `${API_BASE_URL}${PROPERTIES_API}`;
			const response = await axios.get(URL, {
				params: params,
			});

			response.data.links.shift();
			response.data.links.pop();

			commit("SET_ITEMS", {
				items: response.data.data,
				currentPage: response.data.current_page,
				totalPages: response.data.last_page,

				paginationLinks: {
					first: response.data.first_page_url,
					last: response.data.last_page_url,
					prev: response.data.prev_page_url,
					next: response.data.next_page_url,
					pages: response.data.links,
				},
			});
			return response.data.data
		} catch (error) {
			commit("SET_ERROR", {
				status: true,
				message:
					error.response.status == 422
						? error.response.data.message
						: "Error en el servidor",
			});
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
