<template>
  <section id="faqs_main_arae" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>{{ $t("frequently_asked_questions") }}</h2>
          </div>
        </div>
      </div>
      <div class="faqs_area_top">
        <div class="row">
          <div class="col-lg-8">
            <div class="faqs_three_area_wrapper">
              <!-- Item One -->
              <div class="faqs_item_wrapper">
                <div class="faqs_main_item">
                  <div class="section_heading_center">
                    <h2>{{ $t("hosts") }}</h2>
                  </div>
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          {{ $t("faq_1_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_1_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          {{ $t("faq_2_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_2_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          {{ $t("faq_3_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_3_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFour">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          {{ $t("faq_4_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_4_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFive">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          {{ $t("faq_5_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_5_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSix">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          {{ $t("faq_6_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSix"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_6_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingSeven">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          {{ $t("faq_7_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingSeven"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_7_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingEight">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          {{ $t("faq_8_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseEight"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingEight"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_8_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingNine">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                          {{ $t("faq_9_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseNine"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingNine"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_9_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTen">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTen"
                          aria-expanded="false"
                          aria-controls="collapseTen"
                        >
                          {{ $t("faq_10_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseTen"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTen"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_10_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOnef1">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnef1"
                          aria-expanded="false"
                          aria-controls="collapseOnef1"
                        >
                          {{ $t("faq_11_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseOnef1"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOnef1"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_11_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOnef2">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnef2"
                          aria-expanded="false"
                          aria-controls="collapseOnef2"
                        >
                          {{ $t("faq_12_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseOnef2"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOnef2"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_12_hosts") }}
                          </p>
                          <p>
                            {{ $t("rfaq_12_hosts_1") }}
                          </p>
                          <p>
                            {{ $t("rfaq_12_hosts_2") }}
                          </p>
                          <p>
                            {{ $t("rfaq_12_hosts_3") }}
                          </p>
                          <p>
                            {{ $t("rfaq_12_hosts_4") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOnef3">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnef3"
                          aria-expanded="false"
                          aria-controls="collapseOnef3"
                        >
                          {{ $t("faq_13_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseOnef3"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOnef3"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_13_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOnef4">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnef4"
                          aria-expanded="false"
                          aria-controls="collapseOnef4"
                        >
                          {{ $t("faq_14_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseOnef4"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOnef4"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_14_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOnef5">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnef5"
                          aria-expanded="false"
                          aria-controls="collapseOnef5"
                        >
                          {{ $t("faq_15_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseOnef5"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOnef5"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_15_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOnef6">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnef6"
                          aria-expanded="false"
                          aria-controls="collapseOnef6"
                        >
                          {{ $t("faq_16_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseOnef6"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOnef6"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_16_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOnef7">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnef7"
                          aria-expanded="false"
                          aria-controls="collapseOnef7"
                        >
                          {{ $t("faq_17_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseOnef7"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOnef7"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_17_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOnef8">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnef8"
                          aria-expanded="false"
                          aria-controls="collapseOnef8"
                        >
                          {{ $t("faq_18_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseOnef8"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOnef8"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_18_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOnef9">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnef9"
                          aria-expanded="false"
                          aria-controls="collapseOnef9"
                        >
                          {{ $t("faq_19_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseOnef9"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOnef9"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_19_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOnef10">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOnef10"
                          aria-expanded="false"
                          aria-controls="collapseOnef10"
                        >
                          {{ $t("faq_20_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseOnef10"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOnef10"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_20_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwof1">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwof1"
                          aria-expanded="false"
                          aria-controls="collapseTwof1"
                        >
                          {{ $t("faq_21_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseTwof1"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwof1"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_21_hosts") }}
                          </p>
                          <p>
                            {{ $t("rfaq_21_hosts_1") }}
                          </p>
                          <p>
                            {{ $t("rfaq_21_hosts_2") }}
                          </p>
                          <p>
                            {{ $t("rfaq_21_hosts_3") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwof2">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwof2"
                          aria-expanded="false"
                          aria-controls="collapseTwof2"
                        >
                          {{ $t("faq_22_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseTwof2"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwof2"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_22_hosts") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwof3">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwof3"
                          aria-expanded="false"
                          aria-controls="collapseTwof3"
                        >
                          {{ $t("faq_23_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseTwof3"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwof3"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_23_hosts") }}
                          </p>
                          <p>
                            {{ $t("rfaq_23_hosts_1") }}
                          </p>
                          <p>
                            {{ $t("rfaq_23_hosts_2") }}
                          </p>
                          <p>
                            {{ $t("rfaq_23_hosts_3") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwof4">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwof4"
                          aria-expanded="false"
                          aria-controls="collapseTwof4"
                        >
                          {{ $t("faq_24_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseTwof4"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwof4"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_24_hosts") }}
                          </p>
                          <p>
                            {{ $t("rfaq_24_hosts_1") }}
                          </p>
                          <p>
                            {{ $t("rfaq_24_hosts_2") }}
                          </p>
                          <p>
                            {{ $t("rfaq_24_hosts_3") }}
                          </p>
                          <p>
                            {{ $t("rfaq_24_hosts_4") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwof5">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwof5"
                          aria-expanded="false"
                          aria-controls="collapseTwof5"
                        >
                          {{ $t("faq_25_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseTwof5"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwof5"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_25_hosts") }}
                          </p>
                          <p>
                            {{ $t("rfaq_25_hosts_1") }}
                          </p>
                          <p>
                            {{ $t("rfaq_25_hosts_2") }}
                          </p>
                          <p>
                            {{ $t("rfaq_25_hosts_3") }}
                          </p>
                          <p>
                            {{ $t("rfaq_25_hosts_4") }}
                          </p>
                          <p>
                            {{ $t("rfaq_25_hosts_5") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwof6">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwof6"
                          aria-expanded="false"
                          aria-controls="collapseTwof6"
                        >
                          {{ $t("faq_26_hosts") }}
                        </button>
                      </h2>
                      <div
                        id="collapseTwof6"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwof6"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_26_hosts") }}
                          </p>
                          <p>
                            {{ $t("rfaq_26_hosts_1") }}
                          </p>
                          <p>
                            {{ $t("rfaq_26_hosts_2") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="faqs_main_item">
                  <div class="section_heading_center">
                    <h2>{{ $t("guests") }}</h2>
                  </div>
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingf1One">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsef1One"
                          aria-expanded="false"
                          aria-controls="collapsef1One"
                        >
                          {{ $t("faq_1_guest") }}
                        </button>
                      </h2>
                      <div
                        id="collapsef1One"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingf1One"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_1_guest") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingf1Two">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsef1Two"
                          aria-expanded="false"
                          aria-controls="collapsef1Two"
                        >
                          {{ $t("faq_2_guest") }}
                        </button>
                      </h2>
                      <div
                        id="collapsef1Two"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingf1Two"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_2_guest") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingf1Three">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsef1Three"
                          aria-expanded="false"
                          aria-controls="collapsef1Three"
                        >
                          {{ $t("faq_3_guest") }}
                        </button>
                      </h2>
                      <div
                        id="collapsef1Three"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingf1Three"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_3_guest") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingf1Four">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsef1Four"
                          aria-expanded="false"
                          aria-controls="collapsef1Four"
                        >
                          {{ $t("faq_4_guest") }}
                        </button>
                      </h2>
                      <div
                        id="collapsef1Four"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingf1Four"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_4_guest") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingf1Five">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsef1Five"
                          aria-expanded="false"
                          aria-controls="collapsef1Five"
                        >
                          {{ $t("faq_5_guest") }}
                        </button>
                      </h2>
                      <div
                        id="collapsef1Five"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingf1Five"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_5_guest") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingf1Six">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsef1Six"
                          aria-expanded="false"
                          aria-controls="collapsef1Six"
                        >
                          {{ $t("faq_6_guest") }}
                        </button>
                      </h2>
                      <div
                        id="collapsef1Six"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingf1Six"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_6_guest") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingf1Seven">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsef1Seven"
                          aria-expanded="false"
                          aria-controls="collapsef1Seven"
                        >
                          {{ $t("faq_7_guest") }}
                        </button>
                      </h2>
                      <div
                        id="collapsef1Seven"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingf1Seven"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_7_guest") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingf1Eight">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsef1Eight"
                          aria-expanded="false"
                          aria-controls="collapsef1Eight"
                        >
                          {{ $t("faq_8_guest") }}
                        </button>
                      </h2>
                      <div
                        id="collapsef1Eight"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingf1Eight"
                      >
                        <div class="accordion-body">
                          <p>
                            {{ $t("rfaq_8_guest") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="faqs_call_area">
              <img src="../../assets/img/icon/call.png" alt="img" />
              <h5>{{ $t("contact_us") }}</h5>
              <h3><a href="tel:+57-604-256-3458">+57 604 256 3458</a></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "FaqArea",
};
</script>
