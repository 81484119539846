<template>
  <section id="tour_details_main" class="section_padding">
    <div class="container">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="section_heading_center">
          <h2>{{ $t("terms_and_conditions_sl") }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="terms_service_content">
            <div class="terms_item" style="text-align: justify">
              <ol>
                <li>
                  <p>
                    <strong>{{ $t("introduction") }}</strong>
                  </p>
                  <p>{{ $t("tc_introduction") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("reservations_and_cancellation_policy") }}</strong>
                  </p>
                  <p>{{ $t("rc_p1") }}</p>
                  <p>{{ $t("rc_p2") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("owner_responsibility") }}</strong>
                  </p>
                  <p>{{ $t("or_p1") }}</p>
                  <p>{{ $t("or_p2") }}</p>
                  <p>{{ $t("or_p3") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("cancellations_and_property_responsibility") }}</strong>
                  </p>
                  <p>{{ $t("cpr_p1") }}</p>
                  <p>{{ $t("cpr_p2") }}</p>
                  <p>{{ $t("cpr_p3") }}</p>
                  <p>{{ $t("cpr_p4") }}</p>
                  <p>{{ $t("cpr_p5") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("rates_and_payments") }}</strong>
                  </p>
                  <p>{{ $t("rp_p1") }}</p>
                  <p>{{ $t("rp_p2") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("limitations_of_liability") }}</strong>
                  </p>
                  <p>{{ $t("lol_p1") }}</p>
                  <p>{{ $t("lol_p2") }}</p>
                  <p>{{ $t("lol_p3") }}</p>
                  <p>{{ $t("lol_p4") }}</p>
                  <p>{{ $t("lol_p5") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("user_conduct") }}</strong>
                  </p>
                  <p>{{ $t("uc_p1") }}</p>
                  <p>{{ $t("uc_p2") }}</p>
                  <p>{{ $t("uc_p3") }}</p>
                  <p>{{ $t("uc_p4") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("dispute_resolution") }}</strong>
                  </p>
                  <p>{{ $t("dp_p1") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("modifications") }}</strong>
                  </p>
                  <p>{{ $t("md_p1") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("applicable_law_and_jurisdiction") }}</strong>
                  </p>
                  <p>{{ $t("alj_p1") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("contact") }}</strong>
                  </p>
                  <p>{{ $t("cnt_p1") }}</p>
                  <p>{{ $t("cnt_p2") }}</p>
                  <p>{{ $t("cnt_p3") }} soporte@selahcomfort.com</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "PrivacyPolicyContent",
};
</script>
