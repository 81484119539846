<template>
  <!-- Common Banner Area -->
  <!-- <Banner /> -->

  <!-- Form Area -->
  <div style="margin-top: 120px">
    <KeepAlive>
      <Form
        :props_start_date="start_date"
        :props_end_date="end_date"
        :props_city="city"
        :props_country="country"
        :props_destiny="destiny"
        :props_guests="guests"
        :props_nationality="customerNationality"
      />
    </KeepAlive>
  </div>

  <!-- Hotel Search Areas -->
  <SearchResult />

  <!-- Cta Area 
    <Cta />-->
</template>

<script>
// @ is an alias to /src
import Banner from "@/components/hotel/Banner.vue";
import Form from "@/components/home/Form.vue";
import SearchResult from "@/components/hotel/SearchResult.vue";
// import Cta from "@/components/home/Cta.vue";
import axios from "axios";
import { API_BASE_URL, PROPERTIES_API } from "@/api-config";

export default {
  name: "HotelSearchView",
  components: {
    Banner,
    Form,
    SearchResult,
  },

  data() {
    return {
      items: [],
      start_date: this.$route.query.start_date,
      end_date: this.$route.query.end_date,
      city: this.$route.query.city,
      country: this.$route.query.country,
      destiny: {
        id: this.$route.query.destiny_id,
        slug: this.$route.query.destiny_slug,
        text: this.$route.query.destiny_text,
      },
      guests: this.deserializeGuests(this.$route.query.guests),
      customerNationality: this.$route.query.customerNationality,
    };
  },

  methods: {
    deserializeGuests(guestsString) {
      return guestsString.split("&").map((guest) => {
        const adultsMatch = guest.match(/adults=\[(\d+)\]/);
        const childrenMatch = guest.match(/children=\[([\d,]*)\]/);

        return {
          adults: adultsMatch ? parseInt(adultsMatch[1], 10) : 0,
          children:
            childrenMatch && childrenMatch[1]
              ? childrenMatch[1].split(",").map(Number)
              : [],
        };
      });
    },

    fetchProperties() {
      axios
        .get(`${API_BASE_URL}${PROPERTIES_API}`, {
          params: {
            start_date: this.start_date,
            end_date: this.end_date,
            city: this.city,
            country: this.country,
            guest: this.guests,
            // adults: this.adults,
            // children: this.children,
            // scores: this.scores,
            // reviews: this.reviews,
          },
        })
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching properties:", error);
        });
    },
  },
};
</script>
