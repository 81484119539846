<template>
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="tou_booking_form_Wrapper">
            <div class="tour_booking_form_box mb-4">
              <div class="booking_success_arae">
                <div class="booking_success_img">
                  <img src="../../assets/img/icon/right.png" alt="img" />
                </div>
                <div class="booking_success_text">
                  <h3>Su pedido fue enviado exitosamente</h3>
                  <h6>
                    Los datos de su reserva se enviaran a su correo:
                    {{ booking_data.leadGuest.contact.email }}
                  </h6>
                  <br />
                  <h3 v-if="paymentMessage != ''">{{ paymentMessage }}</h3>
                </div>
              </div>
            </div>
            <div class="booking_tour_form">
              <h3 class="heading_theme">Su información</h3>
              <div class="tour_booking_form_box">
                <div class="your_info_arae">
                  <ul>
                    <li>
                      <span class="name_first">Nombres:</span>
                      <span class="last_name">{{
                        booking_data.leadGuest.name.first
                      }}</span>
                    </li>
                    <li>
                      <span class="name_first">Apellidos:</span>
                      <span class="last_name">{{
                        booking_data.leadGuest.name.last
                      }}</span>
                    </li>
                    <li>
                      <span class="name_first">Correo electrónico:</span>
                      <span class="last_name">{{
                        booking_data.leadGuest.contact.email
                      }}</span>
                    </li>
                    <li>
                      <span class="name_first">Dirección:</span>
                      <span class="last_name">{{
                        booking_data.leadGuest.contact.address
                      }}</span>
                    </li>
                    <li>
                      <span class="name_first">País:</span>
                      <span class="last_name">{{
                        booking_data.leadGuest.contact.country
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="booking_tour_form_submit">
            <button @click="openPaymentModal" class="btn btn_theme btn_md">Pagar</button>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="tour_details_right_sidebar_wrapper">
            <div class="tour_detail_right_sidebar">
              <div class="tour_details_right_boxed">
                <div class="tour_details_right_box_heading">
                  <h3>Detalles de la reserva</h3>
                </div>
                <div class="tour_booking_amount_area">
                  <ul>
                    <li>
                      Estadia por {{ diffInDays }} {{ diffInDays > 1 ? "noches" : "noche"
                      }}<span>
                        {{ booking_data.rooms[0].expectedPrice.currency }}
                        {{
                          totalAmount.toLocaleString("es-ES", {
                            minimumFractionDigits: 2,
                          })
                        }}</span
                      >
                    </li>
                  </ul>
                  <div class="tour_bokking_subtotal_area">
                    <div class="tour_bokking_subtotal_area">
                      <h6>
                        Subtotal
                        <span>
                          {{
                            booking_data.rooms?.length > 0
                              ? booking_data.rooms[0].expectedPrice.currency
                              : ""
                          }}
                          {{
                            totalAmount.toLocaleString("es-ES", {
                              minimumFractionDigits: 2,
                            })
                          }}</span
                        >
                      </h6>
                    </div>
                  </div>
                  <div class="total_subtotal_booking">
                    <h6>
                      Monto total
                      <span>
                        {{
                          booking_data.rooms.length > 0
                            ? booking_data.rooms[0].expectedPrice.currency
                            : ""
                        }}
                        {{
                          totalAmount.toLocaleString("es-ES", {
                            minimumFractionDigits: 2,
                          })
                        }}</span
                      >
                    </h6>
                    <sub>*Impuestos incluidos</sub>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TourBooking",
  data() {
    return {
      pay_link: "",
      paymentMessage: "",
      booking_data: {
        leadGuest: {
          contact: {
            email: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            country: "",
          },
          name: {
            first: "",
            last: "",
          },
        },
        rooms: [
          {
            roomId: 0,
            roomName: "",
            rateCode: "",
            expectedPrice: {
              searchCurrency: 0,
              currency: "",
              comission: 0,
              total: 0,
              amount: 0,
              amount: 0,
              discount: 0,
              promotion: [],
              data: "",
            },
            guests: [
              {
                birthDate: "",
                name: { first: "", last: "" },
              },
            ],
            specialRequests: [],
          },
        ],
      },
      diffInDays: 0,
    };
  },
  mounted() {
    this.pay_link = this.$route.query.pay_link;
    this.booking_data = JSON.parse(this.$route.query.booking_data);
    console.log(this.booking_data);

    this.paymentCheckout = new PaymentCheckout.modal({
      client_app_code: process.env.VUE_APP_CLIENT_APP_CODE,
      client_app_key: process.env.VUE_APP_CLIENT_APP_KEY,
      locale: process.env.VUE_APP_LOCALE,
      env_mode: process.env.VUE_APP_ENV_MODE,
      onOpen: () => {
        console.log("modal open");
      },
      onClose: () => {
        console.log("modal closed");
      },
      onResponse: (response) => {
        console.log("modal response", response);
        this.handlePaymentResponse(response);
      },
    });

    this.calculateDiffInDays();
  },
  computed: {
    selectedCountry() {},

    totalAmount() {
      return this.booking_data.rooms.reduce((total, room) => {
        return (total += room.expectedPrice.total);
      }, 0);
    },

    totalTaxesDisplayAmount() {
      if (Array.isArray(this.rooms)) {
        return this.booking_data.rooms.reduce((total, room) => {
          return total + room.totalTaxesDisplay;
        }, 0);
      }
      return 0;
    },
  },

  methods: {
    openPaymentModal() {
      fetch(this.pay_link, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(JSON.stringify(errorData));
          }
          return response.json();
        })
        .then((data) => {
          this.paymentCheckout.open(JSON.parse(data.value));
        })
        .catch((error) => {
          let errorData;
          try {
            errorData = JSON.parse(error.message);
          } catch (e) {
            errorData = error.message;
          }
          console.error("Error en la solicitud:", errorData.message);
          this.paymentCheckout.close();
        });

      window.addEventListener("popstate", () => {
        this.paymentCheckout.close();
      });
    },
    handlePaymentResponse(response) {
      const { status, status_detail } = response.transaction;

      if (status === "success" && status_detail === 3) {
        this.paymentMessage = "Su pago fue aprobado satisfactoriamente";
      } else if (status === "failure" && status_detail === 9) {
        this.paymentMessage = "Su pago fue Rechazado";
      } else if (status === "pending" && status_detail === 1) {
        this.paymentMessage = "Su pago se encuentra en revision";
      } else if (status === "failure" && status_detail === 11) {
        this.paymentMessage = "Pago rechazado por el sistema antifraude";
      } else if (status === "failure" && status_detail === 12) {
        this.paymentMessage = "Tarjeta en black list";
      } else {
        this.paymentMessage = "Unknown status";
      }
    },

    calculateDiffInDays() {
      const fromDate = new Date(this.booking_data.dates.from);
      const toDate = new Date(this.booking_data.dates.to);
      this.diffInDays = (toDate - fromDate) / (1000 * 3600 * 24);
    },
  },
};
</script>
