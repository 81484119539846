<template>
  <!-- Common Banner Area -->
  <!-- <PrivacyPolicyBanner /> -->

  <!-- Privacy Policy Areas -->
  <TermsConditionsContent />

  <!-- Cta Area -->
  <!-- <Cta /> -->
</template>
<script>
import TermsConditionsContent from "@/components/pages/TermsConditionsContent.vue";

export default {
  name: "TermsConditionsView",
  components: {
    // PrivacyPolicyBanner,
    TermsConditionsContent,
    // Cta,
  },
};
</script>
