<template>
  <!-- Common Banner Area -->
  <!-- <HotelDetailBanner /> -->

  <!-- Hotel Search Areas -->
  <HotelSearch :hotelId="hotelId" :props_guests="guests" />

  <!-- Testimonials Area -->
  <!-- <Testimonials /> -->

  <!--Related hotel packages Area -->
  <!-- <RecommendedProperties /> -->

  <!-- Cta Area -->
  <!-- <Cta /> -->
</template>
<script>
import HotelDetailBanner from "@/components/hotel/HotelDetailBanner.vue";
import HotelSearch from "@/components/hotel/HotelSearch.vue";
import RecommendedProperties from "@/components/home/RecommendedProperties.vue";
// import Testimonials from "@/components/pages/Testimonials.vue";
// import Cta from "@/components/home/Cta.vue";

export default {
  name: "HotelDetailsView",
  components: {
    HotelDetailBanner,
    HotelSearch,
    // Testimonials,
    RecommendedProperties,
    // Cta,
  },
  data() {
    return {
      hotelId: this.$route.params.id,
      guests: this.deserializeGuests(this.$route.query.guests),
    };
  },
  methods: {
    deserializeGuests(guestsString) {
      return guestsString.split("&").map((guest) => {
        const adultsMatch = guest.match(/adults=\[(\d+)\]/);
        const childrenMatch = guest.match(/children=\[([\d,]*)\]/);

        return {
          adults: adultsMatch ? parseInt(adultsMatch[1], 10) : 0,
          children:
            childrenMatch && childrenMatch[1]
              ? childrenMatch[1].split(",").map(Number)
              : [],
        };
      });
    },
  },
};
</script>
