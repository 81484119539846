<template>
  <!-- Common Banner Area -->
  <Banner />

  <!--  Common Author Area -->
  <CommonAuthorThree />
</template>
<script>
import Banner from "@/components/owner/Banner.vue";
import CommonAuthorThree from "@/components/user/CommonAuthorThree.vue";
export default {
  name: "RegisterView",
  components: {
    Banner,
    CommonAuthorThree,
  },
};
</script>
